import Container from "./container";

export default function Footer() {
  return (
    <footer>
      <Container>
        <div></div>
      </Container>
    </footer>
  );
}
